var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Feedback',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" Provide contextual feedback messages for typical user actions with the handful of available and flexible alert messages. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('variant')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('additional-content')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('links')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('dismissible')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('auto-dismissing')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('fading')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }